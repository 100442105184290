export default function normalizaRota(rota) {
  const url = new URL(rota, "http://url");

  let path = url.pathname.split("/").join("_");
  path = path.substring(0, 1) == "_" ? path.substring(1) : path;
  path =
    path.substring(path.length - 1, path.length) == "_" ? path.substring(0, path.length - 1) : path;

  return path;
}
